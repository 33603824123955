import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { GET, POST } from '../../Utils/fetch';
import { FormattedMessage, useIntl } from 'react-intl';
import { COUNTRY, EJE_MUI_THEME } from '../../Utils/constant';
import Swal from 'sweetalert2';
import './index.scss';

const InterestedCompanyPopup = ({ open: propOpen }) => {
  const intl = useIntl();

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [otherCompany, setOtherCompany] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [open, setOpen] = useState(propOpen);

  const eje_country_type = localStorage.getItem('eje_country_type');
  const isKorean = eje_country_type ? eje_country_type === COUNTRY.TYPE.KOREAN : true;

  useEffect(async () => {
    // Fetch company list
    const fetchCompanyList = async () => {
      try {
        const companyList = await GET({
          url: '/auth/signup/companies',
          header: {
            EJE_API_KEY: 'auth',
          },
        });
        if (companyList) {
          if (companyList.companies && companyList.companies.length > 0) setCompanyList(companyList.companies);
        }
      } catch (error) {
        console.error(error);
      }
    };

    await fetchCompanyList();
  }, []);

  const handleCompanies = (e) => {
    if (e.target.value.includes('0') && !selectedCompanyIds.includes('0')) {
      setOtherCompany('');
    }
    setSelectedCompanyIds(e.target.value);
  };

  const onClose = () => {
    setSelectedCompanyIds([]);
    setOtherCompany('');
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);

      await POST({
        url: '/user/interested-companies',
        body: {
          company_ids: selectedCompanyIds.join(', '),
          other_company: otherCompany,
        },
      }).then((res) => {
        if (res.success) {
          Swal.fire({
            icon: 'success',
            iconColor: '#f24462',
            text: '관심기업이 저장되었습니다.',
            footer: "발급된 쿠폰은 <a href='/my/coupon' style='color: #f24462;'>마이페이지</a>에서 확인해주세요.",
            confirmButtonColor: '#f24462',
          }).then(() => {
            onClose();
          });
        } else {
          const { message } = res;
          Swal.fire({
            icon: 'error',
            iconColor: '#f24462',
            text: intl.formatMessage({
              id: message ? message : 'ERROR_GENERAL',
            }),
            confirmButtonColor: '#f24462',
          }).then(() => {
            setIsSaving(false);
          });
        }
      });
    } catch (err) {
      console.error(err);
      setIsSaving(false);
      Swal.fire({
        icon: 'error',
        iconColor: '#f24462',
        text: intl.formatMessage({
          id: err.message ? err.message : 'ERROR_GENERAL',
        }),
        confirmButtonColor: '#f24462',
      }).then(() => {
        setIsSaving(false);
      });
    }
  };

  return (
    <ThemeProvider theme={EJE_MUI_THEME}>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>관심기업을 선택해주세요</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography variant="body2" color="textSecondary">
            <p
              style={{
                color: '#f24462',
              }}
            >
              참여만 해도 즉시 사용 가능한 10% 추가 할인 쿠폰을 지급해드려요.
            </p>
          </Typography>
        </DialogContent>
        <DialogContent>
          <Select
            multiple
            value={selectedCompanyIds}
            onChange={handleCompanies}
            renderValue={(selected) =>
              selected?.length ? (
                [...companyList, { id: '0', name: isKorean ? '기타(직접 입력)' : 'Other' }]
                  .filter((companyItem) => selected.includes(companyItem.id))
                  .map((companyItem) => companyItem.name)
                  .join(', ')
              ) : (
                <FormattedMessage id="SIGNUP_FORM_INTERESTED_COMPANY_SELECT" />
              )
            }
            displayEmpty
            fullWidth
            color="pink"
          >
            {companyList.map((companyItem) => (
              <MenuItem key={companyItem.id} value={companyItem.id}>
                {companyItem.name}
              </MenuItem>
            ))}
            <MenuItem value="0">기타(직접 입력)</MenuItem>
          </Select>
          {selectedCompanyIds.includes('0') && (
            <TextField
              fullWidth
              margin="normal"
              label="기타 기업명"
              value={otherCompany}
              onChange={(e) => setOtherCompany(e.target.value)}
              color="pink"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="pink">
            닫기
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={
              isSaving || selectedCompanyIds.length === 0 || (selectedCompanyIds.includes('0') && !otherCompany)
            }
            color="pink"
          >
            {isSaving ? '저장 중...' : '저장'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default InterestedCompanyPopup;
